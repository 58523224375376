<style lang="scss" scoped>
@import "../styles/section";

#gallery-next,
#gallery-prev {
  color: white;
  height: 100%;
  width: 48px;
  background-color: rgba(#000000, 0);
  top: 0;
  bottom: 0;
  margin-top: 0;
  transition: 250ms;
  z-index: 2;
  //&:hover {
  //  background-color: rgba(#000000, 0.5);
  //}
  &:after {
    font-size: 2rem;
  }
}

#gallery-next {
  right: 0;
}

#gallery-prev {
  left: 0;
}

.place {
  //background: linear-gradient(45deg, $primary-dark, $primary);
  background: linear-gradient(45deg, $secondary-dark, $secondary);

  .zoom-clip {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#000000, 0.9);
    backdrop-filter: blur(4px);

    img {
      width: 100%;
      height: auto;
    }
  }

  .section-header {
    background-color: $primary-dark;

    .gallery-container {
      height: 100%;
      width: 100%;

      .gallery-slide {
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
        transition: 250ms;
        //filter: grayscale(100%);
        //z-index: 1;
        .swiper-slide-active {
          filter: grayscale(0);
        }

        @media screen and (min-device-width: 900px) {
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: url("../assets/zoom.svg");
            background-position: center;
            background-repeat: no-repeat;
            transition: 250ms;
            opacity: 0;
          }
          &:hover {
            //transform: scale(1.025);
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .section-content {
    padding: 0 0 8px 0;
    background: linear-gradient(-45deg, $primary, $primary-dark);
    border-color: $secondary-dark;
    color: $secondary-dark;

    .container {
      padding: 1rem;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-between;
      .stay-tuned {
        color: inherit;
        transition: 150ms;
        &:hover,
        &:active {
          color: white;
        }
        margin-left: auto;
        display: flex;
        align-items: center;
        font-family: "adobe-handwriting-ernie", serif;
        text-indent: 101%;
        white-space: nowrap;
        overflow: hidden;

        svg {
          margin-right: 0.5rem;
        }
      }

      h2 {
        margin: 0;

        span {
          color: white;
        }
      }
    }

    //&:hover {
    //  .thumbs-container {
    //    height: 150px;
    //  }
    //}

    .thumbs-container {
      height: 150px;
      padding: 0 8px;
      //transition: 350ms;

      .thumbs-slide {
        width: 180px;
        background-size: cover;
        position: relative;
        //filter: grayscale(100%);

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(#000000, 0.3);
          z-index: 1;
          transition: 250ms;
          cursor: pointer;
        }

        &:hover,
        &.swiper-slide-thumb-active {
          filter: grayscale(0);

          &:after {
            background-color: transparent;
          }
        }
      }
    }
  }
}

div.gallery-slide.swiper-slide.swiper-slide-active {
  filter: grayscale(0) !important;
}
</style>

<template>
  <main class="section place">
    <transition
      @before-enter="fadeBeforeEnter"
      @enter="fadeEnter"
      @leave="fadeLeave"
    >
      <aside
        class="zoom-clip swiper-no-swiping"
        v-if="$store.getters['gallery/getImage']"
        @click="$store.commit('gallery/CLOSE')"
      >
        <!--        <div class="close">Fermer</div>-->
        <img
          ref="image"
          :src="$store.getters['gallery/getImage'].url"
          :alt="$store.getters['gallery/getImage'].label"
        />
      </aside>
    </transition>
    <div class="section-header">
      <div class="gallery-container swiper-container">
        <div id="gallery-next" class="swiper-button-next"></div>
        <div id="gallery-prev" class="swiper-button-prev"></div>
        <div class="gallery-wrapper swiper-wrapper">
          <div
            v-for="image in $store.getters['gallery/getImages']"
            :key="image.url"
            class="gallery-slide swiper-slide"
            :style="`background-image: url(${image.url})`"
            @click="$store.commit('gallery/OPEN', { url: image.url })"
          ></div>
        </div>
      </div>
    </div>
    <transition
      @before-enter="staggerBeforeEnter"
      @enter="staggerEnter"
      @leave="staggerLeave"
    >
      <div class="section-content">
        <div class="container">
          <h2 class="section-title">patrol<span>place</span></h2>
          <a
            class="stay-tuned"
            href="https://www.instagram.com/patrol_paris"
            target="_blank"
            rel="noopener"
          >
            <stay-tuned></stay-tuned>
            <icon-instagram size="48"></icon-instagram>
          </a>
        </div>
        <div class="thumbs-container swiper-container">
          <div class="thumbs-wrapper swiper-wrapper">
            <div
              v-for="image in $store.getters['gallery/getImages']"
              :key="image.url"
              class="thumbs-slide swiper-slide"
              :style="`background-image: url(${image.url})`"
            ></div>
          </div>
          <div class="swiper-scrollbar swiper-scrollbar-white"></div>
        </div>
      </div>
    </transition>
  </main>
</template>

<script>
import { Swiper, Thumbs, Scrollbar, Navigation } from "swiper";
import "swiper/swiper-bundle.css";

Swiper.use([Thumbs, Scrollbar, Navigation]);

import { stagger, fade } from "@/js/gsap-transitions-hooks";

import StayTuned from "@/components/StayTuned";

export default {
  name: "About",
  components: { StayTuned },
  methods: {
    ...stagger,
    ...fade,
    setResponsiveImage() {
      let img;
      const zoomClip = document.querySelector(".zoom-clip");
      if (zoomClip) {
        img = zoomClip.querySelector("img");
      }
      if (window.orientation > 0) {
        img.style.height = "100%";
        img.style.width = "auto";
      } else {
        img.style.height = "auto";
        img.style.width = "100%";
      }
    }
  },
  mounted() {
    // this.setResponsiveImage();
    window.addEventListener(
      "orientationchange",
      this.setResponsiveImage,
      false
    );
    const thumbs = new Swiper(".thumbs-container", {
      wrapperClass: "thumbs-wrapper",
      slideClass: "thumbs-slide",
      // loop: true,
      // freeMode: true,
      spaceBetween: 8,
      slidesPerView: "auto",
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      scrollbar: {
        el: ".swiper-scrollbar"
      },
      breakpoints: {
        "@1.0": {
          slidesPerView: 4
        },
        "@1.25": {
          slidesPerView: 6
        }
        // "@2.00": {
        //   slidesPerView: 8
        // }
      }
    });
    new Swiper(".gallery-container", {
      // centeredSlides: true,
      slidesPerView: 1,
      // breakpoints: {
      //   "@1.0": {
      //     slidesPerView: 1
      //   },
      //   "@1.50": {
      //     slidesPerView: 2
      //   },
      //   "@2.00": {
      //     slidesPerView: 3
      //   }
      // },
      // spaceBetween: 16,
      wrapperClass: "gallery-wrapper",
      slideClass: "gallery-slide",
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      thumbs: {
        swiper: thumbs
      }
    });
  }
};
</script>
